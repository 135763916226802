import React from "react";
import "./Services.css";
import { BsCheck } from "react-icons/bs";
import { FaExternalLinkSquareAlt } from "react-icons/fa";

const Services = () => {
  return (
    <section id="services">
      <h5>Here are</h5>
      <h2>My Projects</h2>
      <div className="container service_container">
        <article className="service">
          <div className="service_head">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <h3>Heartsybox</h3>
              <a href="https://heartsybox.com">
                <FaExternalLinkSquareAlt color="black" />
              </a>
            </div>
          </div>
          <ul className="service_list">
            <li>
              <BsCheck className="service_list-icon" />
              <p>
                Sole developer of Heartsybox, an innovative e-commerce platform
                designed to create an engaging online shopping experience
              </p>
            </li>
            <li>
              <BsCheck className="service_list-icon" />
              <p>
                Crafted a responsive user interface using ReactJs, ensuring
                optimal user experience across various devices and browsers
              </p>
            </li>
            <li>
              <BsCheck className="service_list-icon" />
              <p>
                Engineered a robust backend with NodeJs and MongoDB, focusing on
                high performance and scalability to handle growing user traffic
              </p>
            </li>
            <li>
              <BsCheck className="service_list-icon" />
              <p>
                Integrated a continuous integration and deployment pipeline with
                GitHub Actions and Digital Ocean, resulting in a 30% improvement
                in deployment efficiency
              </p>
            </li>
            <li>
              <BsCheck className="service_list-icon" />
              <p>
                Employed Bootstrap and Ant Design for a sleek and modern design,
                which increased customer retention rates by 15%.
              </p>
            </li>
          </ul>
        </article>

        <article className='service'>
        <div className="service_head">
          <div style={{display:'flex', justifyContent:'space-between'}}>
          <h3>AslasChat</h3>
          <a href='https://www.aslaschat.ai/'>
          <FaExternalLinkSquareAlt color='black'/>
          </a>
          </div>    
        </div>
        <ul className='service_list'>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>Took the initiative as a Full-stack Engineer to integrate AI-driven chatbots across platforms such as Meta, Slack, and Skype, enhancing customer service and business automation</p>
          </li>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>Orchestrated the development of chatbots employing VueJS, NestJs, and AWS with websockets to craft intelligent and scalable customer interaction tools</p>
          </li>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>Pioneered the use of machine learning techniques to facilitate sophisticated data scraping, leading to more personalized customer experiences</p>
          </li>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>The technology fusion and strategic implementation delivered a versatile chat solution that could be embedded across various client websites</p>
          </li>
        </ul>
      </article>

        <article className='service'>
        <div className="service_head">
          <div style={{display:'flex', justifyContent:'space-between'}}>
          <h3>RefurBlock</h3>
          <a href='https://process.r-cubes.com/'>
          <FaExternalLinkSquareAlt color='black'/>
          </a>
          </div>    
        </div>
        <ul className='service_list'>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>Full-stack engineer on RefurBlock, a warehouse management system for refurbished electronics, improving operational efficiency and product tracking</p>
          </li>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>Developed and maintained the software, enabling real-time tracking of inventory, which reduced inventory discrepancies by 20%</p>
          </li>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>Utilized VueJS for the frontend to create an interactive and intuitive user interface for warehouse management</p>
          </li>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>Implemented backend services with Laravel and MySQL, achieving a 25% increase in database query performance</p>
          </li>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>Established a CI/CD pipeline using Docker and Google AppSheet, which decreased the overall software release cycle by 50%</p>
          </li>
        </ul>
      </article>

      <article className='service'>
        <div className="service_head">
          <div style={{display:'flex', justifyContent:'space-between'}}>
          <h3>Pikit</h3>
          <a href='https://dev.pikit-backend.satussystems.com/'>
          <FaExternalLinkSquareAlt color='black'/>
          </a>
          </div>    
        </div>
        <ul className='service_list'>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>Led the backend development for Pikit, a dynamic platform for social interaction and service booking</p>
          </li>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>Designed a comprehensive backend system capable of handling user profiles, location-based services, and real-time notifications, increasing user engagement by 40%</p>
          </li>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>Integrated Stripe for secure and seamless payment processing, which enhanced the user checkout experience</p>
          </li>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>Applied a technology stack that included Flutter, NestJS, MySQL, and Firebase to support a scalable and maintainable architecture</p>
          </li>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>Implemented real-time communication using Socket.io, which supported instant updates and notifications to users</p>
          </li>
        </ul>
      </article>

      <article className='service'>
        <div className="service_head">
          <div style={{display:'flex', justifyContent:'space-between'}}>
          <h3>Simi Smart</h3>
          <a href='https://simi-connect.com/'>
          <FaExternalLinkSquareAlt color='black'/>
          </a>
          </div>    
        </div>
        <ul className='service_list'>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>Undertook full-stack development for Simi Smart, a digital out-of-home (DOOH) advertising project that boosted client revenue through strategic platform integrations</p>
          </li>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>Developed with VueJs and NestJs to provide a stable and responsive user experience, attracting an increase of 30% in client ad engagement</p>
          </li>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>Leveraged Redis and MongoDB for high-performance data handling and storage, which supported the delivery of targeted advertising content</p>
          </li>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>Incorporated Auth0 for secure and reliable user authentication, ensuring user data privacy and trust</p>
          </li>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>Utilized a combination of Kotlin and Flutter to extend functionality to mobile platforms, reaching a broader audience for the client</p>
          </li>
        </ul>
      </article>

      
      <article className='service'>
        <div className="service_head">
          <div style={{display:'flex', justifyContent:'space-between'}}>
          <h3>Inventory Management System</h3>
          <a href='https://simi-connect.com/' style={{display:'none'}}>
          <FaExternalLinkSquareAlt color='black'/>
          </a>
          </div>    
        </div>
        <ul className='service_list'>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>Independently developed a comprehensive inventory system, focusing on high security and scalability, to cater to the complex needs of product management</p>
          </li>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>Engineered using ReactJs, NestJs, and PostgreSQL, the system provided robust inventory tracking and management capabilities</p>
          </li>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>Designed with an intuitive user interface, facilitating easy adoption and operation by end-users</p>
          </li>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>The system was successfully commercialized, demonstrating its viability and generating additional revenue streams</p>
          </li>
        </ul>
      </article>

      <article className='service'>
        <div className="service_head">
          <div style={{display:'flex', justifyContent:'space-between'}}>
          <h3>Fittler</h3>
          <a href='https://fittlerfyp.netlify.app/' >
          <FaExternalLinkSquareAlt color='black'/>
          </a>
          </div>    
        </div>
        <ul className='service_list'>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>Created an integrated Android/web platform, Fittler, aimed at encouraging physical health tracking and well-being</p>
          </li>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>The platform featured a full suite of health-tracking functionalities, including activity monitoring, diet recommendations, and a global user ranking system</p>
          </li>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>Employed a tech stack comprising React Native, Vue.js, Express.js, Firebase, and NodeJs to ensure a responsive and user-friendly experience</p>
          </li>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>Garnered positive user feedback for its impact on users' health routines and its motivational global ranking feature</p>
          </li>
        </ul>
      </article>

      <article className='service'>
        <div className="service_head">
          <div style={{display:'flex', justifyContent:'space-between'}}>
          <h3>Calendar</h3>
          <a href='https://simi-connect.com/' style={{display:'none'}}>
          <FaExternalLinkSquareAlt color='black'/>
          </a>
          </div>    
        </div>
        <ul className='service_list'>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>As a Full-stack Engineer, developed a feature-rich calendar application, addressing complex scheduling needs and comprehensive user authentication systems</p>
          </li>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>The application was built using ReactJs and NestJs, ensuring a smooth and responsive user interface that facilitated efficient time management</p>
          </li>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>Implemented advanced features such as event reminders, scheduling algorithms, and user collaboration tools</p>
          </li>
          <li>
            <BsCheck className='service_list-icon'/>
            <p>The calendar application stood out for its intuitive design, which significantly improved user engagement and productivity</p>
          </li>
        </ul>
      </article>



      </div>
    </section>
  );
};

export default Services;
