import React from "react";
import "./About.css";
import { FaAward, FaFolderOpen } from "react-icons/fa";
import {GiGraduateCap} from "react-icons/gi"
import img from "../../assets/images/me3.jpg";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className="container about_container">
        <div className="about_me">
          <div className="about_me_img">
            <img src={img} alt="Me" />
          </div>
        </div>
        <div className="about_content">
          <div className="about_cards">
            <article className="about_card">
              <FaAward className="about_icon" />
              <h5>Experience</h5>
              <br/>
              <small><span>2022-2023 - Aslase Enterprise </span></small>
              <br/>
              <small>2022-2023 - Satus Systems</small>
              <br/>
              <small>2022-2023  -  Gigalabs</small>
              <br/>
              <small>2021-2022 - Freelance</small>
            </article>
            <article className="about_card">
              <GiGraduateCap className="about_icon" />
              <h5>Degree</h5>
              <small>BS CS <br/>Comsats University</small>
            </article>
            {/* <article className="about_card">
              <FaFolderOpen className="about_icon" />
              <h5>Projects</h5>
              <small>6 projects</small>
            </article> */}
           
          </div>
            <p>
              My Name is Mahad Younis. Dynamic Software Developer with over 2 years of experience specializing in full-stack development, from concept to deployment. Proven track record in delivering innovative solutions that drive revenue and efficiency gains. Expertise in a diverse tech stack including ReactJS, Node.js, Vue.js, NestJS, and Laravel. Excels in fast-paced environments, leveraging a strong foundation in both front-end and back-end technologies to produce scalable and robust software solutions.
            </p>
            <a href="#contact" className="btn btn-primary">
              Lets Talk
            </a>
        </div>
      </div>
    </section>
  );
};

export default About;
