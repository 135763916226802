import React, { useState } from "react";
import "./Contact.css";
import { HiOutlineMail } from "react-icons/hi";
import { SiMessenger, SiWhatsapp } from "react-icons/si";
import { useRef } from "react";
import emailjs from "emailjs-com";
import { message, Spin } from "antd";

const Contact = () => {
  const form = useRef();
  const [loader, setLoader] = useState(false);
  const sendEmail = async (e) => {
    e.preventDefault();
    try {
      setLoader(true)
      const res = await emailjs.sendForm(
        "service_kkk7ijc",
        "template_zqzo8r3",
        form.current,
        "1FLNJ7IXpasRPDkyb"
      );
      if (res.status === 200) {
        message.success("Details has been submitted Successfully");
        setLoader(false)
        e.target.reset();
      }
    } catch (err) {
      message.error("Error Sending Details");
      setLoader(false)
    }

    
  };
  return (
    <>
      {loader && <Spin fullscreen />}
      <section id="contact">
        <h5>Get in Touch</h5>
        <h2>Contact Me</h2>
        <div className="container contact_container">
          <div className="contact_options">
            <article className="contact_option">
              <HiOutlineMail className="contact_option-icon" />
              <h4>Email</h4>
              <h5>younismahad123@gmail.com</h5>
              <a
                href="mailto: younismahad123@gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                Send a message
              </a>
            </article>
            <article className="contact_option">
              <SiMessenger className="contact_option-icon" />
              <h4>Messenger</h4>
              <h5>Mahad Younis</h5>
              <a
                href="https://m.me/100010236002250"
                target="_blank"
                rel="noreferrer"
              >
                Send a message
              </a>
            </article>
            <article className="contact_option">
              <SiWhatsapp className="contact_option-icon" />
              <h4>Whatsapp</h4>
              <h5>My Number</h5>
              <a
                href="https://api.whatsapp.com/send?phone=+923377655419"
                target="_blank"
                rel="noreferrer"
              >
                Send a message
              </a>
            </article>
          </div>
          <form ref={form} onSubmit={sendEmail}>
            <input
              type="text"
              name="name"
              placeholder="Your full name"
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              required
            />
            <textarea
              name="message"
              rows="7"
              placeholder="Your Message"
              required
            />
            <button type="submit" className="btn btn-primary" disabled={loader}>
              Send Message
            </button>
          </form>
        </div>
      </section>
    </>
  );
};

export default Contact;
