import React from 'react'
import './Footer.css';
import {BsFacebook, BsInstagram, BsTwitter} from 'react-icons/bs'
import {BsLinkedin, BsGithub} from 'react-icons/bs'



const Footer = () => {
  return (
    <footer id = "footer">
     
      <ul className='permalinks'>
        <li><a href='#'>Home</a></li>
        <li><a href='#about'>About</a></li>
        <li><a href='#experience'>Experience</a></li>
        <li><a href='#service'>Services</a></li>
        <li><a href='#contact'>Contact</a></li>
      </ul>
      <div className="footer_socials">
        <a href='https://www.facebook.com/profile.php?id=100011548338367' target= "_blank" rel="noreferrer"><BsFacebook/></a>
        <a href='https://www.instagram.com/mahadmirza1/' target= "_blank" rel="noreferrer"><BsInstagram/></a>
        <a href='' target= "_blank" rel="noreferrer"><BsTwitter/></a>
        <a  href='https://www.linkedin.com/in/mahad-younis-45954820a/' target= "_blank" rel="noreferrer"><BsLinkedin/></a>
        <a href='https://github.com/Mahad336' target= "_blank" rel="noreferrer"><BsGithub/></a>
      </div>
      <div className="footer_copyright">
        <small>&copy; Mahad Younis. All rights reserved</small>
      </div>
    </footer>
  )
}

export default Footer