import React from "react";
import "./Experience.css";
import { BsFillPatchCheckFill } from "react-icons/bs";

const Experience = () => {
  return (
    <section id="experience">
      <h5>What Skills I have?</h5>
      <h2>My Skills</h2>
      <div className="container experience_container">
        <div className="experience_frontend">
          <h3>FrontEnd Development</h3>
          <div className="experience_content">
            <article className="experience_details">
              <BsFillPatchCheckFill className="experience_details-icon" />
              <h4>React</h4>
              {/* <small className='text-light'>Experienced</small> */}
            </article>
            <article className="experience_details">
              <BsFillPatchCheckFill className="experience_details-icon" />
              <h4>Vue</h4>
              {/* <small className='text-light'>Experienced</small> */}
            </article>
            <article className="experience_details">
              <BsFillPatchCheckFill className="experience_details-icon" />
              <h4>Material UI</h4>
              {/* <small className='text-light'>Experienced</small> */}
            </article>
            <article className="experience_details">
              <BsFillPatchCheckFill className="experience_details-icon" />
              <h4>Antd</h4>
              {/* <small className='text-light'>Experienced</small> */}
            </article>
            <article className="experience_details">
              <BsFillPatchCheckFill className="experience_details-icon" />
              <h4>Bootstrap</h4>
              {/* <small className='text-light'>Experienced</small> */}
            </article>
          </div>
        </div>

        <div className="experience_backend">
          <h3>BackEnd Development</h3>
          <div className="experience_content">
            <article className="experience_details">
              <BsFillPatchCheckFill className="experience_details-icon" />
              <h4>Node</h4>
              {/* <small className='text-light'>Experienced</small> */}
            </article>
            <article className="experience_details">
              <BsFillPatchCheckFill className="experience_details-icon" />
              <h4>Nest</h4>
              {/* <small className='text-light'>Experienced</small> */}
            </article>
            <article className="experience_details">
              <BsFillPatchCheckFill className="experience_details-icon" />
              <h4>Laravel</h4>
              {/* <small className='text-light'>Experienced</small> */}
            </article>
          </div>
        </div>

        <div className="experience_frontend">
          <h3>Databases</h3>
          <div className="experience_content">
            <article className="experience_details">
              <BsFillPatchCheckFill className="experience_details-icon" />
              <h4>Mysql</h4>
              {/* <small className='text-light'>Experienced</small> */}
            </article>
            <article className="experience_details">
              <BsFillPatchCheckFill className="experience_details-icon" />
              <h4>Nosql</h4>
              {/* <small className='text-light'>Experienced</small> */}
            </article>
            <article className="experience_details">
              <BsFillPatchCheckFill className="experience_details-icon" />
              <h4>MongoDB</h4>
              {/* <small className='text-light'>Experienced</small> */}
            </article>
            <article className="experience_details">
              <BsFillPatchCheckFill className="experience_details-icon" />
              <h4>Redis</h4>
              {/* <small className='text-light'>Experienced</small> */}
            </article>
            <article className="experience_details">
              <BsFillPatchCheckFill className="experience_details-icon" />
              <h4>Firestore | Realtime Database</h4>
              {/* <small className='text-light'>Experienced</small> */}
            </article>
          </div>
        </div>

        <div className="experience_frontend">
          <h3>Devops</h3>
          <div className="experience_content">
            <article className="experience_details">
              <BsFillPatchCheckFill className="experience_details-icon" />
              <h4>Virtual Private Server</h4>
              {/* <small className='text-light'>Experienced</small> */}
            </article>
            <article className="experience_details">
              <BsFillPatchCheckFill className="experience_details-icon" />
              <h4>Shared Hosting</h4>
              {/* <small className='text-light'>Experienced</small> */}
            </article>
            <article className="experience_details">
              <BsFillPatchCheckFill className="experience_details-icon" />
              <h4>Linux</h4>
              {/* <small className='text-light'>Experienced</small> */}
            </article>
            <article className="experience_details">
              <BsFillPatchCheckFill className="experience_details-icon" />
              <h4>Nginx & Apache</h4>
              {/* <small className='text-light'>Experienced</small> */}
            </article>
            <article className="experience_details">
              <BsFillPatchCheckFill className="experience_details-icon" />
              <h4>AWS</h4>
              {/* <small className='text-light'>Experienced</small> */}
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Experience;
